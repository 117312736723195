import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import Container from "layouts/Container";

import "./legal-disclosures.scss";

const LegalDisclosures = ({ data: { page, footerImage } }) => {
  const { t } = useTranslation();
  const { seo } = page.frontmatter;
  const title = t("Legal Disclosures");

  return (
    <Layout footerImage={footerImage}>
      {/* Adds default coming from the article, but can be overwritten by Seo fields */}
      <SEO title={(seo && seo.title) || title} description={seo && seo.description} image={seo && seo.image} />
      <Container>
        <div className="legal-disclosures">
          <h1 className="legal-disclosures__title t-title-1">{title}</h1>
          <hr className="legal-disclosures__divider" />
          <div className="legal-disclosures__body t-text" dangerouslySetInnerHTML={{ __html: page.html }} />
        </div>
      </Container>
    </Layout>
  );
};

export default LegalDisclosures;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(
      fields: { langKey: { eq: $language } }
      fileAbsolutePath: { regex: "//pages/legal-disclosures.md/" }
    ) {
      html
      frontmatter {
        seo {
          ...Seo
        }
      }
    }
    footerImage: file(name: { eq: "home2-large" }) {
      ...BgImage
    }
  }
`;
